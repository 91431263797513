<template>
    <CRow>
        <CCol col="12" xl="12">
            <Spinner v-if="$store.state.loadSpinner" />

            <CCard v-else>
                <CCardHeader> المستخدمون </CCardHeader>
                <CCardBody>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover mb-5">
                            <thead>
                                <tr class="table-primary">
                                    <th scope="col">#</th>
                                    <th scope="col">الاسم</th>
                                    <th scope="col">الموبايل</th>
                                    <th scope="col">الحالة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" :key="item.id">
                                    <th scope="row">{{ item.id }}</th>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.phone }}</td>
                                    <td>
                                        <b :class="`text-${getBadge(item.status)}`">
                                            {{ item.status == "1" ? "نشط" : "متوقف" }}
                                        </b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <CPagination :active-page.sync="currentPage" :pages="totalPages" />
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
export default {
    name: "Users",
    data() {
        return {
            items: [],
            activePage: 1,
            currentPage: 1,
            allPages: 10,
        };
    },
    watch: {
        currentPage: {
            handler: function (val) {
                this.fetchData(val);
            },
            deep: true,
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData(page = 1) {
            this.toggleLoadSpinner();

            this.$http
                .get(`users?page=${page}`)
                .then((res) => {
                    // handle success
                    this.items = res.data.data.data;
                    this.totalPages = res.data.data.last_page;
                    this.toggleLoadSpinner();
                })
                .catch(() => {
                    //
                    this.toggleLoadSpinner();
                });
        },

        toggleLoadSpinner() {
            this.$store.commit("toggleLoadSpinner");
        },

        stopUser(itemId) {
            console.log(itemId);
        },
        getBadge(status) {
            switch (status) {
                case 1:
                    return "success";
                case 0:
                    return "danger";
                default:
                    "primary";
            }
        },
        pageChange(val) {
            this.$router.push({ query: { page: val } });
        },
    },
};
</script>